.article {
  .advert {
    &__store {
      h1 {
        font-size: $px32;

        @include mobile {
          font-size: $px32-mob;
        }
      }

      .advert__text {
        width: 100%;

        @include mobile {
          padding: 4vw 0 4vw 4vw;
        }
      }
    }
  }

  &__holder {
    width: 88%;

    @include mobile {
      width: 95%;
    }
  }

  h2 {
    color: $black;
    font-size: $px32;
    text-transform: none;
    margin-top: 3vw;
    margin-bottom: 2vw;

    @include mobile {
      font-size: $px32-mob;
    }
  }

  h4 {
    width: 100%;
    font-weight: $font-bold;
  }

  p {
    font-size: $px18;
    margin-top: 0.5vw;
    margin-bottom: 2vw;
    line-height: 2;

    @include mobile {
      font-size: $px18-mob;
    }
  }

  ul {
    padding-inline-start: 1.3vw;
    font-size: $px18;
    line-height: 2;

    @include mobile {
      font-size: $px18-mob;
    }

    li {
      &::marker {
        color: #db2525;
        font-size: $px24;

        @include mobile {
          font-size: $px24-mob;
        }
      }
    }
  }

  .advert__store {
    width: 100%;
  }
}
