.footer {
  width: $container;
  margin-top: 3vw;
  padding: 1vw 0 3vw 0;
  border-top: 1px solid rgba(51, 51, 51, 0.5);

  @include mobile {
    width: $container-mob;
    margin-top: 8vw;
  }

  &__middle {
    margin: 1.5vw 0;
  }

  &__bottom {
    font-size: $px10;
    color: $gr2;

    img {
      margin-top: 0.5vw;
    }

    @include mobile {
      font-size: $px10-mob;

      &-design {
        margin-right: 3vw;
      }
    }
  }

  &__logos {
    margin-top: 1vw;

    &-title {
      width: 100%;
      margin-bottom: 1vw;
      font-size: $px12;

      @include mobile {
        margin-bottom: 4vw;
        font-size: $px12-mob;
      }
    }

    &-wrapper {
      height: 2vw;
      gap: 1vw;

      @include mobile {
        height: 7vw;
        gap: 3vw;
      }
    }

    &-logo {
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;

      img {
        height: 100%;
        width: auto;
      }

      &--mc img {
        height: 150%;
      }

      &--mir img {
        height: 70%;
      }

      &--visa img {
        height: 230%;
      }
    }
  }

  &__titles {
    text-transform: uppercase;

    &-title {
      color: $gr2;
      opacity: 0.5;
      font-size: $px14;
      font-weight: $font-medium;
      white-space: nowrap;
      margin-right: 1.8vw;
      line-height: 300%;

      @include mobile {
        font-size: $px14-mob;
        width: 100%;
        text-align: center;
      }
    }
  }

  &__lang {
    /* temporarily */
    display: none;

    margin-right: 2vw;
    font-size: $px12;

    @include mobile {
      font-size: $px12-mob;
    }
  }

  &__social {
    @include mobile {
      display: none;
    }
  }
}
