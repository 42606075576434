.login {
  .advert__store {
    width: 100%;

    h1 {
      font-size: $px32;

      @include mobile {
        font-size: $px32-mob;
      }
    }
  }

  &__holder {
    width: 100%;
    padding: 1vw 4vw 3vw;
  }

  &__type {
    flex-wrap: wrap;
    position: relative;

    @include mobile {
      margin-top: 4vw;
      flex-wrap: wrap;
    }
  }

  &__enter {
    width: 100%;
    margin: 2vw 0 3vw;
  }

  &__choose {
    width: 49%;
    background-color: #3262aa;
    padding: 1vw 1.5vw 2vw;
    border-radius: 0.5vw;
    cursor: pointer;

    @include mobile {
      width: 100%;
      margin-bottom: 3vw;
      padding: 6vw 2.5vw 6vw;
      border-radius: 2vw;
    }

    h5 {
      margin-bottom: 1.5vw;
      color: rgba(255, 255, 255, 0.6);
    }

    h1 {
      color: $white;
    }
  }

  &__form {
    width: 75%;

    @include mobile {
      width: 100%;
      flex-wrap: wrap;
      flex-direction: column-reverse;
    }

    input,
    select {
      border: 0.05vw solid #323232;
      margin: 0.5vw 0 1vw;
      font-size: $px20;
      appearance: none;

      @include mobile {
        font-size: 4.1vw;
      }

      &[type='checkbox'] {
        width: 1vw;
        height: 1vw;
        margin: 0.2vw;

        @include mobile {
          width: 5vw;
          height: 5vw;
          margin: 1vw;
        }
      }
    }

    label {
      width: 100%;
      font-size: $px18;

      @include mobile {
        font-size: $px18-mob;
      }
    }

    button {
      width: 70%;
      margin: 1vw 0;

      @include mobile {
        width: 100%;
        margin-top: 4vw;
      }
    }

    a {
      text-decoration: underline;
      font-weight: $font-bold;
    }

    h5 {
      margin-top: 1vw;
    }

    &-left,
    &-right {
      width: 45%;

      @include mobile {
        width: 100%;
      }
    }

    &-left {
      .jcfs-aic {
        @include mobile {
          white-space: nowrap;
          flex-wrap: wrap;
        }
      }
    }

    &-right {
      &--required {
        padding: 0.35vw 0;
      }

      h5 {
        margin-top: 1.3vw;
      }
    }
  }

  &__signin {
    .login__form {
      button {
        width: 48%;
      }

      h5 {
        margin-top: 0;
        margin-left: 2vw;
        cursor: pointer;
      }
    }
  }

  &__heading {
    color: $black;
    position: absolute;
    top: -4.9vw;

    @include mobile {
      top: -12vw;
    }

    span {
      color: #868686;
      cursor: pointer;
      transition: all 0.2s ease;

      &.bold,
      &:hover {
        color: $black;
      }
    }
  }
}

.password__reset {
  &.login__form {
    @include mobile {
      flex-direction: column;
    }
  }

  .login__form-left {
    width: 100%;
    gap: 4vw;

    @include mobile {
      flex-direction: column;
    }
  }
}
