.home {
  &__banner {
    width: 100%;

    @include mobile {
      height: 70vw;

      img {
        object-fit: cover;
        height: 100%;
      }
    }

    &-container {
      width: $container;

      @include mobile {
        width: $container-mob;
      }
    }

    &-holder {
      top: 0;
      left: 0;
    }

    &-info {
      width: 57%;
      color: $white;
      margin-top: 2.5vw;

      @include mobile {
        width: 100%;
      }

      &-text {
        display: none;

        font-size: $px14;
        line-height: 129%;
        opacity: 0.8;
        margin-top: 0.75vw;

        @include mobile {
          font-size: $px14-mob;
        }
      }
    }
  }

  &__top-slider-text {
    margin: 2vw 0 -2vw 0;
  }

  &__map {
    h2 {
      margin-bottom: 0;
    }

    img {
      margin-top: 2vw;
    }

    h5 {
      font-size: $px18;
      margin-top: 0.5vw;

      @include mobile {
        font-size: $px16-mob;
      }
    }

    &-holder {
      padding-top: 4vw;
      margin-top: 2vw;
    }

    &-zones {
      top: 0;
      left: 0;

      .popup {
        position: absolute;
        background-color: transparent;
        width: auto;
        height: auto;
        left: calc(50% - 9vw);
        top: calc(50% - 7.75vw);
        z-index: 20;

        @include mobile {
          left: 0;
          top: 0;
          position: fixed;
          width: 100%;
          height: 100%;
          background-color: rgba(0, 0, 0, 0.5);
        }

        &__holder {
          width: 18vw;
          min-height: 15.5vw;
          display: flex;

          @include mobile {
            width: 90vw;
            min-height: 60vw;
          }
        }

        &__close img {
          margin-top: 0;
        }
      }

      .form {
        padding: 3vw 2vw 0;
        width: 100%;

        @include mobile {
          padding: 5vw 5vw 0;
        }
      }
    }

    &-popup {
      h4 {
        width: 100%;
      }
    }

    &-svg {
      transition: 0.3s ease-out;
    }

    &-zone {
      transition: 0.3s ease-out;
      padding-top: 2vw;
      border-width: 0.01vw;
      border-style: solid;
      border-image: linear-gradient(to bottom, #ffffff 0%, #d1d1d1 50%, #ffffff 100%) 1 100%;

      @include mobile {
        border: none;
      }

      &--pins {
        top: 0;
        left: 0;

        svg {
          width: 1.5vw;
          height: 1.5vw;

          @include mobile {
            width: 2vw;
            height: 2vw;
          }
        }
      }

      &:hover {
        .home__map-zone--gradient {
          opacity: 0.2;
        }

        .home__map-svg {
          fill: $white;
        }
      }

      &--gradient {
        top: 0;
        left: 0;
        transition: 0.3s ease-out;
        background: linear-gradient(
          180deg,
          rgba(227, 0, 14, 0),
          #e3000e 6.25%,
          #e3000e 93.75%,
          rgba(227, 0, 14, 0)
        );
        opacity: 0;
      }

      &--time {
        white-space: nowrap;
        z-index: 1;

        @include mobile {
          display: none;
        }

        h4 {
          font-weight: $font-bold;
        }
      }

      &--2 {
        width: 9%;

        .home__map-zone--pins {
          &-1 {
            top: 73%;
            left: 40%;
          }
        }
      }

      &--3 {
        width: 13.5%;

        .home__map-zone--pins {
          &-1 {
            left: 35%;
            top: 55%;
          }

          &-2 {
            left: 80%;
            top: 57%;
          }

          &-3 {
            left: 80%;
            top: 63%;
          }

          &-4 {
            left: 54%;
            top: 70%;
          }

          &-5 {
            left: 72%;
            top: 73%;
          }

          &-6 {
            left: 66%;
            top: 80%;
          }

          &-7 {
            left: 79%;
            top: 85%;
          }
        }
      }

      &--4 {
        width: 4%;

        .home__map-zone--pins {
          &-1 {
            top: 69%;
            left: 8%;
          }
        }
      }

      &--5 {
        width: 8.6%;

        .home__map-zone--pins {
          &-1 {
            top: 68%;
            left: 42%;
          }

          &-2 {
            top: 62%;
            left: 62%;
          }

          &-3 {
            top: 64%;
            left: 9%;
          }

          &-4 {
            top: 58%;
            left: 31%;
          }
        }
      }

      &--6 {
        width: 4.4%;
      }

      &--7 {
        width: 11.1%;

        .home__map-zone--pins {
          &-1 {
            top: 67%;
            left: 5%;
          }
        }
      }

      &--8 {
        width: 9.4%;
      }

      &--9 {
        width: 8.3%;
      }

      &--10 {
        width: 10%;

        .home__map-zone--pins {
          &-1 {
            top: 82%;
            left: 2%;
          }

          &-2 {
            top: 75%;
            left: 15%;
          }
        }
      }

      &--11 {
        width: 4%;
      }

      &--12 {
        width: 20.1%;

        .home__map-zone--pins {
          &-1 {
            top: 68%;
            left: -3%;
          }
        }
      }
    }
  }

  &__partners {
    &-wrapper {
      margin-bottom: 50px;
    }

    h2 {
      margin-bottom: 3vw;
    }

    &-links {
      display: grid;
      grid-template-columns: repeat(5, 1fr);
      gap: 3vw;

      @include mobile {
        grid-template-columns: repeat(3, 1fr);
        gap: 6vw;
      }
    }

    &-link {
      height: 120px;
      display: block;

      img {
        object-fit: contain;
        height: 100%;
      }
    }
  }

  &__race {
    /* temporarily */
    display: none;

    margin: 4vw 0;
  }

  &__store {
    button {
      margin-top: 1.5vw;
    }
  }

  &__slider {
    margin: 4vw 0 1vw;

    &-slide {
      outline: none;
      padding: 0 0.5vw;

      img {
        border-radius: 24px;
        object-fit: cover;

        $slide-size-desktop: 15.55vw;

        width: $slide-size-desktop;
        height: $slide-size-desktop;

        @include mobile {
          $slide-size-mobile: 46.6vw;

          width: $slide-size-mobile;
          height: $slide-size-mobile;
        }
      }
    }

    .slick {
      &-prev,
      &-next {
        z-index: 10;
        width: 3vw;
        height: auto;

        @include mobile {
          width: 8vw;
        }

        &::before {
          display: none;
        }
      }

      &-prev {
        top: calc(50% - 1.5vw);
        left: 1.75vw;
        transform: scale(-1, 1);

        @include mobile {
          top: calc(50% - 4vw);
        }
      }

      &-next {
        right: 1.5vw;
      }
    }
  }
}
