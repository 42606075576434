@font-face {
  font-family: 'Gotham Pro';
  src: url('../../font/GothamPro-Italic.eot');
  src: local('Gotham Pro Italic'), local('GothamPro-Italic'),
    url('../../font/GothamPro-Italic.eot?#iefix') format('embedded-opentype'),
    url('../../font/GothamPro-Italic.woff') format('woff'),
    url('../../font/GothamPro-Italic.ttf') format('truetype');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'Gotham Pro';
  src: url('../../font/GothamPro-Medium.eot');
  src: local('Gotham Pro Medium'), local('GothamPro-Medium'),
    url('../../font/GothamPro-Medium.eot?#iefix') format('embedded-opentype'),
    url('../../font/GothamPro-Medium.woff') format('woff'),
    url('../../font/GothamPro-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Gotham Pro';
  src: url('../../font/GothamPro-Bold.eot');
  src: local('Gotham Pro Bold'), local('GothamPro-Bold'),
    url('../../font/GothamPro-Bold.eot?#iefix') format('embedded-opentype'),
    url('../../font/GothamPro-Bold.woff') format('woff'),
    url('../../font/GothamPro-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Gotham Pro';
  src: url('../../font/GothamPro-Black.eot');
  src: local('Gotham Pro Black'), local('GothamPro-Black'),
    url('../../font/GothamPro-Black.eot?#iefix') format('embedded-opentype'),
    url('../../font/GothamPro-Black.woff') format('woff'),
    url('../../font/GothamPro-Black.ttf') format('truetype');
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: 'Gotham Pro';
  src: url('../../font/GothamPro.eot');
  src: local('Gotham Pro'), local('GothamPro'),
    url('../../font/GothamPro.eot?#iefix') format('embedded-opentype'),
    url('../../font/GothamPro.woff') format('woff'),
    url('../../font/GothamPro.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}
