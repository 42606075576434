.advert {
  border-radius: 24px;
  background-color: $gr1;
  position: relative;

  &__large {
    padding: 2vw;

    .advert {
      &__text {
        padding-left: 0;
      }

      &__photo {
        img {
          border-top-left-radius: 24px;
          border-bottom-left-radius: 24px;

          @include mobile {
            border-top-right-radius: 24px;
            border-bottom-right-radius: 24px;
          }
        }
      }
    }
  }

  &__margin {
    margin-top: -4vw;

    @include mobile {
      margin-top: -8vw;
    }

    &-big {
      margin-top: -3vw;

      @include mobile {
        margin-top: -9vw;
      }
    }
  }

  &__photo {
    width: 53.5%;

    @include mobile {
      width: 100%;
    }
  }

  &__text {
    width: 44%;
    height: auto;
    padding-left: 2vw;

    h3 {
      margin-top: 0;
    }

    button {
      width: fit-content;
    }

    @include mobile {
      width: 100%;
    }
  }

  &__block {
    p,
    ul li {
      font-size: $px18;
      line-height: 133%;

      @include mobile {
        font-size: $px18-mob;
      }
    }

    ul {
      padding-left: 1.01vw;

      @include mobile {
        padding-left: 4vw;
      }
    }
  }

  &__after p {
    font-size: $px24;
    margin: 2vw 0;

    @include mobile {
      font-size: $px24-mob;
    }
  }

  &__store {
    .advert {
      &__text {
        align-self: stretch;
        padding: 2vw 0 2vw 4vw;
        min-height: 7.5vw;

        @include mobile {
          width: 50%;
          min-height: 20vw;
        }
      }

      &__photo {
        padding: 0.6vw;
        width: 60%;

        @include mobile {
          width: 50%;
        }
      }
    }

    h1 {
      font-size: $px56;

      @include mobile {
        font-size: $px56-mob;
      }
    }
  }
}

.store {
  .advert__margin-big {
    margin-top: -4vw;

    @include mobile {
      margin-top: -8vw;
    }
  }
}
