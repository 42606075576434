* {
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: 'Gotham Pro', sans-serif;
}

body,
html {
  scroll-behavior: smooth;
}

img {
  width: 100%;
}

a {
  text-decoration: none;
  color: inherit;
}

svg {
  display: block;
  width: 100%;
  height: 100%;
}

iframe {
  width: 100%;
  height: 100%;
}

h1 {
  font-weight: $font-bold;
  font-size: $px86;
  margin: 0;

  @include mobile {
    font-size: $px86-mob;
  }
}

h2 {
  font-weight: $font-bold;
  font-size: $px38;
  color: $gr2;
  letter-spacing: $spacing;
  text-transform: uppercase;

  @include mobile {
    font-size: $px38-mob;
  }
}

h3 {
  font-weight: $font-bold;
  font-size: $px24;
  color: $gr2;
  text-transform: uppercase;

  @include mobile {
    font-size: $px24-mob;
  }
}

h4 {
  font-size: $px18;
  font-weight: $font-regular;
  margin: 0;

  @include mobile {
    font-size: $px18-mob;
  }
}

h5 {
  font-size: $px16;
  font-weight: $font-regular;
  color: $gr2;
  margin: 0;

  @include mobile {
    font-size: $px16-mob;
  }
}

h6 {
  font-size: $px9;
  font-weight: $font-regular;
  color: $gr5;
  margin: 0;

  @include mobile {
    font-size: $px9-mob;
  }
}

input {
  @include mobile {
    height: 9vw;
  }
}

input,
textarea,
select {
  width: 100%;
  font-family: 'Gotham Pro', sans-serif;
  border: none;
  border-radius: 0.5vw;
  padding: 0.75vw 1vw;
  outline: none;
  appearance: none;

  &::placeholder {
    font-family: 'Gotham Pro', sans-serif;
    font-style: italic;
    color: #e0e2ee;
    font-size: $px14;
    padding-left: 0.75vw;

    @include mobile {
      font-size: $px14-mob;
    }
  }
}

input[type='checkbox'],
input[type='date'],
input[type='radio'] {
  appearance: auto !important;
}

input[type='checkbox'] {
  -webkit-appearance: checkbox !important;
}

.fixed-symbol {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 1vw;
  margin: auto;
  height: fit-content;
  font-size: 13.333px;
  opacity: 0.4;
  transition: 0.2s ease;

  &__wrapper {
    position: relative;

    &:has(input:focus) .fixed-symbol {
      opacity: 1;
    }

    input {
      padding-left: 1.8vw;

      @include mobile {
        padding-left: 3.5vw;
      }
    }
  }
}

.select {
  position: relative;

  &::after {
    content: ' ';
    position: absolute;
    top: 50%;
    margin-top: -0.3vw;
    right: 0.8vw;
    width: 0;
    height: 0;
    border-left: 0.3vw solid transparent;
    border-right: 0.3vw solid transparent;
    border-top: 0.3vw solid $black;

    @include mobile {
      right: 2vw;
      border-left: 1vw solid transparent;
      border-right: 1vw solid transparent;
      border-top: 1vw solid $black;
    }
  }
}

.h-full {
  height: 100%;
}

.w-full {
  width: 100%;
}

.wh-full,
.hw-full {
  width: 100%;
  height: 100%;
}

.overflow-x {
  overflow-x: hidden;
}

.overflow-y {
  overflow-y: hidden;
}

.overflow {
  overflow: hidden;
}

.inline {
  display: inline !important;
}

.hidden {
  display: none !important;
}

.desktop {
  @include mobile {
    display: none !important;
  }
}

.mobile {
  @include desktop {
    display: none !important;
  }
}

.desktop-hidden {
  @include desktop {
    display: none !important;
  }
}

.mobile-hidden {
  @include mobile {
    display: none !important;
  }
}

.relative {
  position: relative;
}

.absolute {
  position: absolute;
}

.fixed {
  position: fixed;
  top: 0;
  left: 0;
}

[class*='__container'] {
  padding-top: $header-height;

  @include mobile {
    padding-top: $header-height-mob;
  }
}

[class*='__main'] {
  width: $container;

  @include mobile {
    width: $container-mob;
  }
}

.pointer {
  cursor: pointer;
}
