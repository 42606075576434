.text {
  position: absolute;
  background: rgba(61, 61, 61, 0.48);
  border-radius: 3px;
  color: #fff;
  font-size: 10px;
  line-height: 13px;
  padding: 1px 4px 2px;
  text-align: center;
  top: 10px;
  right: 10px;
  text-decoration: underline;
  cursor: pointer;
}

.popupCloseBtn {
  position: absolute;
  top: 5px;
  right: 10px;
  width: 30px;
  height: 30px;
  background-color: #222222;
  border: none;
  border-radius: 100px;
  cursor: pointer;
  z-index: 1;

  &::after,
  &::before {
    content: '';
    position: absolute;
    display: block;
    top: 0;
    left: 0;

    width: 1rem;
    height: 0.125rem;
    background-color: #fff;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  &::before {
    transform: translate(-50%, -50%) rotate(45deg);
  }

  &::after {
    transform: translate(-50%, -50%) rotate(-45deg);
  }
}

.wrapper {
  background: #000;
  color: #fff;
  opacity: 0.4;
  position: relative;
  border-radius: 1.25rem;
}

.ad {
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.reklama {
  position: absolute;
  z-index: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  top: 45%;
}
.items {
  font-size: 0.9rem;
  line-height: 1.2rem;
  font-weight: 500;
}
@media (max-width: 768px) {
  .ad {
    max-width: 20rem;
  }

  .popupCloseBtn {
    top: 10px;
  }

  .reklama {
    top: 40%;
  }

  .items {
    font-size: 8px;
    line-height: 10px;
  }
}
