$px6: 0.35vw;
$px9: 0.45vw;
$px10: 0.5vw;
$px12: 0.59vw;
$px14: 0.7vw;
$px16: 0.8vw;
$px18: 0.9vw;
$px20: 1vw;
$px22: 1.1vw;
$px24: 1.2vw;
$px32: 1.7vw;
$px38: 1.85vw;
$px56: 2.4vw;
$px86: 4.05vw;

$px6-mob: 2.2vw;
$px9-mob: 2.4vw;
$px10-mob: 2.5vw;
$px12-mob: 2.75vw;
$px14-mob: 3vw;
$px16-mob: 3.25vw;
$px18-mob: 3.5vw;
$px20-mob: 3.65vw;
$px22-mob: 3.75vw;
$px24-mob: 4vw;
$px32-mob: 4.5vw;
$px38-mob: 4vw;
$px56-mob: 5vw;
$px86-mob: 6vw;

$spacing: -0.05vw;

$font-regular: 400;
$font-medium: 500;
$font-bold: 700;
$font-black: 900;

$header-height: 5.25vw;
$header-height-mob: 11vw;

$container: 66vw;
$container-mob: 95vw;

$black: #000000;
$white: #ffffff;
$gr1: #f8f8f8;
$gr2: #333333;
$gr3: rgba(51, 51, 51, 0.5);
$gr4: #444444;
$gr5: rgba(0, 0, 0, 0.5);
$deepblue: #315fb6;
$error: #f61414;
