.protocols {
  &__main {
    padding-top: 4vw;

    @include mobile {
      padding-top: 12vw;
    }
  }

  &__section {
    position: relative;
  }

  &__back {
    cursor: pointer;
    position: absolute;
    top: -3vw;
    left: 0;
    font-size: $px16;

    @include mobile {
      top: -12vw;
      font-size: $px16-mob;
    }
  }
}
