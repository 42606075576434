.pagination {
  margin: 1.5vw 0;

  button {
    border: 0.05vw solid #3262aa;
    color: $gr5;
    background-color: transparent;
  }

  &__load {
    margin-bottom: 1.5vw;

    @include mobile {
      margin-bottom: 5vw;
    }
  }

  &__holder {
    width: 40%;

    @include mobile {
      width: 75%;
    }
  }

  &__action {
    width: 100%;
    user-select: none;
  }

  &__page {
    padding: 0.25vw;
    margin: 0 0.25vw;
    cursor: pointer;
    font-size: $px22;

    @include mobile {
      font-size: $px22-mob;
      margin: 0 2vw;
    }
  }

  &__current {
    color: #3262aa;
    font-weight: $font-bold;
  }

  &__move {
    cursor: pointer;
    font-size: $px56;
    color: #c4c4c4;
    line-height: 1;

    @include mobile {
      font-size: $px56-mob;
    }
  }

  &__action {
    color: $gr5;
  }
}
