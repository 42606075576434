.banner {
  @include mobile {
    height: 30vw;

    img {
      object-fit: cover;
      height: 100%;
    }
  }

  &__container {
    color: $white;
    width: $container;
    margin-bottom: 2vw;

    @include mobile {
      width: $container-mob;
    }
  }

  h5 {
    color: $white;
    margin-bottom: 0.5vw;
  }

  h1 {
    line-height: 0.9;
  }
}
