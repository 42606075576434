.partners {
  &__heading,
  &__card {
    margin-top: 3vw;
  }

  &__general {
    width: 58%;
    padding: 1vw;

    @include mobile {
      width: 100%;
      padding: 2vw;
    }

    h2 {
      font-size: $px32;

      @include mobile {
        font-size: $px32-mob;
      }
    }

    h5 {
      margin: 1.5vw 0 2.5vw;
      line-height: 1.4;
    }

    .advert {
      &__photo {
        width: 35%;
        background-color: $white;
        align-self: stretch;
        border-radius: 20px;

        img {
          width: 70%;
        }
      }

      &__text {
        width: 65%;
      }
    }

    .cards__card-button {
      margin-bottom: 1vw;
    }
  }

  &__card {
    display: flex;
    justify-content: space-between;
    align-items: stretch;

    .advert {
      &__photo {
        width: 85%;
        display: flex;
        align-items: center;
      }

      &__text {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding: 2vw;

        p {
          font-size: $px16;

          @include mobile {
            font-size: $px14-mob;
          }
        }
      }
    }
  }
}
