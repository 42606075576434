.contacts {
  &__main {
    padding-top: 2vw;

    @include mobile {
      flex-wrap: wrap;
    }
  }

  &__left {
    width: 66%;

    @include mobile {
      width: 100%;
      margin-bottom: 2vw;
    }
  }

  &__right {
    width: 32%;
    align-self: stretch;

    @include mobile {
      width: 100%;
    }

    .advert {
      padding: 2vw 1.5vw;

      &__text {
        width: 100%;
        padding: 0;
      }

      label {
        font-size: $px18;
        font-weight: $font-medium;
        color: $gr2;
        margin: 1vw 0;

        @include mobile {
          font-size: $px18-mob;

          margin: 3vw 0;
        }
      }

      h2 {
        color: $black;
        text-transform: none;
        margin: 0 0 0.5vw;
      }

      h4,
      h5 {
        color: $gr3;
      }

      h4 {
        font-size: $px16;

        @include mobile {
          font-size: $px16-mob;
        }
      }

      h5 {
        font-size: $px12;

        @include mobile {
          font-size: $px12-mob;
        }
      }

      button {
        width: 100%;
        margin: 1vw 0;

        @include mobile {
          margin: 4vw 0;
        }
      }

      input,
      textarea {
        margin-top: 1vw;

        @include mobile {
          margin-top: 3vw;
        }
      }

      textarea {
        height: 7vw;

        @include mobile {
          height: 20vw;
        }
      }
    }
  }

  &__people {
    padding: 1vw;
    margin-bottom: 2vw;

    .advert {
      &__photo {
        $size-desktop: 10.4vw;

        width: $size-desktop;
        height: $size-desktop;

        @include mobile {
          $size-mobile: 30vw;

          width: $size-mobile;
          height: $size-mobile;
        }

        img {
          object-fit: cover;
          height: 100%;
          border-radius: 20px;
        }
      }

      &__text {
        width: 72%;

        h4 {
          color: $gr3;
          text-transform: uppercase;
        }

        h2 {
          color: $black;
          text-transform: none;
        }
      }
    }
  }

  &__card {
    .advert {
      &__text {
        h3 {
          font-size: $px32;
          margin: 1vw 0;

          @include mobile {
            font-size: $px32-mob;
          }
        }
      }

      &__photo {
        width: 40%;
        display: flex;

        img {
          height: 100%;
          border-radius: 0 24px 24px 0;
          object-fit: cover;
        }

        @include mobile {
          width: 80%;
        }
      }
    }
  }
}
