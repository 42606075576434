.form {
  padding: 3vw 3vw 0;
  background-color: #edeff2;
  border-radius: 1.2vw;

  @include mobile {
    padding: 5vw 4vw 2vw;
    border-radius: 3vw;
  }

  label {
    width: 47%;
    font-size: $px18;
    font-weight: $font-medium;

    @include mobile {
      width: 100%;
      font-size: $px18-mob;
    }

    &.w-full {
      width: 100%;
    }
  }

  input,
  select {
    border: none;
    margin: 1vw 0 2vw;
    appearance: none;

    @include mobile {
      margin: 2vw 0 4vw;
    }
  }

  textarea {
    width: 100%;
    margin: 1vw 0;
    min-height: 8vw;
  }

  h4 {
    font-size: $px18;
    font-weight: $font-medium;
    margin-bottom: 1vw;

    @include mobile {
      font-size: $px18-mob;
      margin-bottom: 4vw;
    }
  }

  .label_checkbox {
    font-weight: $font-regular;
    width: auto;
    margin-left: 0.5vw;
  }

  &__buttons {
    margin-bottom: 2vw;

    button {
      width: auto;
      margin: 0 2vw;

      &:first-child {
        margin: 0;
      }
    }

    &-button {
      &--size {
        margin: 0 1.25vw -0.5vw 0 !important;
        white-space: nowrap;

        @include mobile {
          margin: 0 2vw 2vw 0 !important;
        }
      }
    }

    h5 {
      margin: 0;
    }

    .label_checkbox {
      @include mobile {
        white-space: nowrap;
        flex-wrap: wrap;
        justify-content: flex-start;
        width: 55%;
      }
    }
  }

  &__holder {
    .form__subheading {
      width: 100%;
      margin-bottom: 3vw;
    }
  }
}

form {
  .error {
    color: $error;
    margin: -0.25vw 0 0.5vw;
  }
}
