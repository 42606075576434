.qualifyingRaces {
  &__block {
    margin: 1.5vw 0;

    @include mobile {
      flex-wrap: wrap;
      justify-content: center;
      margin: 15px 0;
    }
  }

  &__sponsors {
    width: 21vw;

    @include mobile {
      width: 100%;
      margin-top: 15px;
    }
  }
}
