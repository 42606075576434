.cart {
  .advert {
    &__store {
      h1 {
        font-size: $px32;

        @include mobile {
          font-size: $px32-mob;
        }
      }

      .advert__text {
        width: 100%;

        @include mobile {
          padding: 4vw 0 4vw 4vw;
        }
      }
    }
  }

  &__holder {
    margin-top: 2vw;

    @include mobile {
      flex-wrap: wrap;
    }
  }

  &__products {
    width: 66%;

    @include mobile {
      width: 100%;
    }

    h4 {
      font-size: $px16;

      @include mobile {
        font-size: $px22-mob;
      }
    }

    h5 {
      font-size: $px12;
      font-weight: $font-bold;
      color: $black;

      @include mobile {
        font-size: $px12-mob;
      }
    }
  }

  &__product {
    padding: 1.5vw 0 0.5vw;
    border-top: 0.05vw solid #e4e4e8;

    &:last-child {
      border-bottom: 0.05vw solid #e4e4e8;
    }

    &-photo {
      width: 25%;
    }

    &-info {
      width: 72%;

      h5 {
        margin-bottom: 0.2vw;
      }

      h6 {
        line-height: 2.7;

        @include mobile {
          line-height: 2.2;
        }
      }

      &--size {
        margin-right: 1vw;

        @include mobile {
          margin-right: 3vw;
        }
      }

      &--data {
        margin: 2vw 0 2vw 0;

        @include mobile {
          margin: 4vw 0 4vw 0;
        }
      }

      &--count {
        user-select: none;

        h4 {
          line-height: 0.7;
          height: 0.5vw;

          @include mobile {
            height: 3vw;
          }
        }

        h5 {
          margin-right: 1vw;
        }

        &-text {
          width: 3vw;
          text-align: center;

          @include mobile {
            width: 12vw;
          }
        }

        &-action {
          cursor: pointer;
          padding: 1vw;
        }
      }

      &--price {
        h2 {
          margin: 0.5vw 0 0 0;
        }
      }
    }

    &-code {
      margin: 0.5vw 0;

      @include mobile {
        margin: 3vw 0;
      }

      &--title {
        width: 25%;
        text-align: right;
      }

      &--confirm {
        width: 25%;
      }

      &--remove {
        cursor: pointer;
      }

      input {
        width: 38%;
        padding: 0.45vw 1vw;
        border: 0.05vw solid #e4e4e8;

        @include mobile {
          width: 33%;
        }
      }
    }
  }

  &__order {
    width: 32%;

    @include mobile {
      width: 100%;
    }

    h4 {
      font-weight: $font-bold;
    }

    label {
      display: block;
      font-size: $px12;

      @include mobile {
        font-size: $px18-mob;
      }
    }

    input,
    select {
      margin: 0.45vw 0;
      border: 0.05vw solid rgba(68, 68, 68, 0.18);
      background-color: transparent;
      border-radius: 0.25vw;

      @include mobile {
        margin: 2vw 0;
      }
    }

    &-header,
    &-body,
    &-footer {
      padding: 1.75vw;

      @include mobile {
        padding: 5vw 2vw;
      }
    }

    &-header,
    &-body {
      border-bottom: 0.05vw solid rgba(68, 68, 68, 0.18);
    }

    &-body,
    &-footer {
      h4 {
        font-size: $px14;

        @include mobile {
          font-size: $px14-mob;
        }
      }
    }

    &-header {
      h4 {
        margin-bottom: 0.5vw;
      }

      h6 {
        font-size: $px10;

        @include mobile {
          font-size: $px18-mob;
        }
      }
    }

    &-body {
      &--title {
        &-first {
          margin-bottom: 2vw;
        }

        &-second {
          margin-top: 2vw;
          margin-bottom: 0.5vw;
        }
      }

      .label-input {
        padding: 0.5vw 0 0.8vw 1.5vw;

        @include mobile {
          padding: 2.5vw 0 2.5vw 5.5vw;
        }
      }

      input[type='radio'] {
        position: absolute;
        left: -0.3vw;
        top: 0;
        width: 1.5vw;

        @include mobile {
          top: -2.5vw;
          width: 3.5vw;
        }
      }
    }

    &-footer {
      h5 {
        font-size: $px12;

        @include mobile {
          font-size: $px22-mob;
        }
      }

      &--sale {
        margin-bottom: 1.5vw;
      }

      &--result {
        h2 {
          margin-top: 1vw;
          font-size: $px24;

          @include mobile {
            font-size: $px24-mob;
          }
        }
      }

      button {
        margin: 0.25vw 0 0.75vw;
        border-radius: 0.25vw;
      }
    }
  }
}
