.store {
  &__holder {
    margin-top: 3vw;
    scroll-margin-top: 10vh;

    @include mobile {
      flex-wrap: wrap;
      flex-direction: column-reverse;
    }
  }

  .advert {
    &__photo {
      width: 100%;
    }
  }

  @include mobile {
    &__main > .advert__store .advert__text {
      width: 100%;
    }
  }

  h5 {
    font-size: $px16;

    @include mobile {
      font-size: $px16-mob;
    }
  }

  &__options {
    width: 24%;

    @include mobile {
      width: 100%;
    }

    .advert {
      margin-bottom: 1.5vw;
    }

    &-ad {
      &.advert {
        @include mobile {
          display: flex;
          justify-content: center;
          align-items: center;
          background-color: transparent;

          .advert__photo {
            width: 50%;
          }
        }
      }
    }

    h4,
    h5 {
      width: 100%;
    }

    h4 {
      text-transform: uppercase;
      font-weight: $font-bold;
    }

    h5 {
      margin-top: 1.5vw;
      color: $gr4;

      @include mobile {
        margin-top: 3vw;
        padding: 1vw 0;
      }
    }

    &-filters,
    &-categories {
      padding: 2vw 1.5vw;

      @include mobile {
        padding: 3vw 3.5vw;
      }
    }

    &-categories {
      &-category {
        cursor: pointer;
        transition: all 0.1s linear;

        &--current {
          color: $deepblue !important;
        }
      }
    }

    &-filters {
      &--range {
        &-wrapper {
          width: 100%;
          position: relative;
          min-height: 2vw;

          input[type='range'] {
            -webkit-appearance: none;
            appearance: none;
            height: 0.2vw;
            width: 100%;
            position: absolute;
            bottom: 0;
            left: 0;
            background-color: #c6c6c6;
            padding: 0;
            pointer-events: none;
          }

          input[type='range']::-webkit-slider-thumb {
            pointer-events: all;
          }
        }

        &--from {
          height: 0 !important;
          bottom: 0.1vw !important;
          z-index: 1;
        }
      }

      &--filters {
        margin-top: 1vw;
        gap: 0.5vw;
        max-width: 92%;

        @include mobile {
          max-width: none;
          gap: 2vw;
        }
      }

      &--filter {
        font-size: $px16;
        width: 2.5vw;
        height: 2.5vw;
        background-color: #eeeeee;
        border: 0.05vw solid #e4e4e8;
        color: $gr4;
        border-radius: 0.25vw;
        cursor: pointer;
        transition: all 0.1s linear;

        @include mobile {
          width: 10vw;
          height: 10vw;
          font-size: $px16-mob;
        }

        &.filter--selected {
          background-color: $deepblue !important;
          color: $white;
        }

        &:hover {
          @include desktop {
            background-color: $deepblue !important;
            color: $white;
          }
        }
      }

      &--price {
        @include mobile {
          gap: 5vw;
        }

        &-to,
        &-from {
          width: 35%;

          @include mobile {
            width: 48%;
          }
        }

        &-to h5 {
          text-align: right;
        }

        input {
          font-size: $px14;
          margin-top: 1vw;
          padding: 0.9vw 0 0.9vw 0.75vw;
          border: 0.05vw solid #e4e4e8;
          border-radius: 0.25vw;

          @include mobile {
            padding: 1vw 5vw;
            font-size: $px14-mob;
          }

          &::placeholder {
            font-style: normal;
            padding-left: 0;
            color: $gr4;
            opacity: 0.6;
          }
        }
      }

      button {
        width: 100%;
        margin-top: 3vw;

        &:last-child {
          margin-top: 1vw;
        }
      }
    }

    &-links {
      @include mobile {
        h5 {
          text-align: center;
        }
      }
    }
  }

  &__goods {
    width: 74%;

    @include mobile {
      width: 100%;
    }
  }

  & &__sort {
    margin-bottom: 1.5vw;

    @include mobile {
      margin: 4vw;
      justify-content: center;
    }

    h5 {
      color: #c4c4c4;
      font-size: $px14;
      margin-right: 1.2vw;

      @include mobile {
        font-size: $px14-mob;
      }
    }

    &-buttons {
      &--type {
        font-size: $px14;
        margin: 0 1.2vw;

        @include mobile {
          font-size: $px14-mob;
        }

        img {
          margin-left: 0.5vw;
          width: 0.8vw;

          @include mobile {
            margin-left: 1.5vw;
            width: 2.8vw;
          }
        }
      }
    }
  }

  &__products {
    margin-bottom: 1vw;

    @include mobile {
      align-items: stretch;
    }
  }

  &__product {
    width: 31%;
    margin-bottom: 1.25vw;
    margin: 0.5vw;
    position: relative;

    @include mobile {
      width: 46.8%;
      margin: 1.5vw;
    }

    a {
      @include mobile {
        display: flex;
        flex-direction: column;
        height: 100%;
      }
    }

    &-photo {
      position: relative;
      border-top-left-radius: 1vw;
      border-top-right-radius: 1vw;
      border: 0.05vw solid $gr1;
      height: 15.4vw;
      flex-shrink: 0;

      @include mobile {
        border-top-left-radius: 5vw;
        border-top-right-radius: 5vw;
        border: 1vw solid #f8f8f8;
        height: 45vw;
      }

      &--soldout {
        position: absolute;
        inset: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: $px24;
        font-weight: $font-medium;
        color: white;
        background-color: rgba(68, 68, 68, 0.7);
        border-radius: 1vw 1vw 0 0;

        @include mobile {
          font-size: $px24-mob;
          border-top-left-radius: 5vw;
          border-top-right-radius: 5vw;
        }
      }

      img {
        height: 100%;
        object-fit: contain;
      }
    }

    &-text.advert {
      padding: 1vw;
      border-radius: 0 0 1vw 1vw;
      min-height: 8vw;

      @include mobile {
        padding: 3vw;
        height: 100%;
      }
    }

    &-action {
      margin-top: 1vw;

      @include mobile {
        margin-top: 5vw;
      }
    }

    &-price {
      font-weight: $font-bold;
      color: $gr4;

      &--button {
        position: absolute;
        bottom: 1vw;
        right: 1vw;

        @include mobile {
          bottom: 3vw;
          right: 3vw;
          padding: 1.6vw 2vw;
        }
      }

      &--old {
        color: rgba(68, 68, 68, 0.6);
        font-size: $px20;
        font-weight: 400;
        text-decoration-line: line-through;

        @include mobile {
          font-size: $px20-mob;
        }
      }
    }
  }
}
