.header {
  z-index: 100;
  height: $header-height;
  background-color: $white;
  font-size: $px16;
  line-height: 1.5;
  padding: 0 2.5vw;

  @include mobile {
    height: $header-height-mob;
    font-size: $px24-mob;
  }

  &__logo {
    @include mobile {
      z-index: 1;
    }
  }

  &__content {
    width: 85%;
    display: flex;
    justify-content: space-between;
    align-items: center;

    @include mobile {
      display: none;
    }
  }

  &__titles {
    width: 67vw;
    margin: 0.25vw 0 0 1.5vw;

    @include mobile {
      width: 90vw;
    }

    &-title {
      text-align: center;
      margin: 0 1vw 0.5vw 0;

      @include mobile {
        width: 100%;
        padding: 2vw 0;
      }
    }
  }

  &__contribution {
    margin-right: 1.6vw;
    display: none !important;

    @include mobile {
      width: 100%;
      display: flex;
      justify-content: center;
      margin: 5vw 0;
    }

    button {
      padding: 0.6vw 0.9vw;
      white-space: nowrap;

      @include mobile {
        padding: 2.5vw 3vw;
        font-size: $px24-mob;
      }
    }
  }

  &__cart {
    width: 3.6vw;

    @include mobile {
      width: 23%;
      margin: 3vw 0.75vw;
    }

    &-logo {
      width: 65%;
    }

    &-info {
      position: relative;
    }

    &-count {
      position: absolute;
      top: -1.1vw;
      bottom: 0;
      left: -0.8vw;
      right: 0;
      margin: auto;

      width: 1.1vw;
      height: 1.1vw;
      font-size: $px10;
      font-weight: $font-bold;
      color: $white;
      background-color: #db2525;
      border-radius: 100%;
      padding-top: 0.05vw;

      @include mobile {
        font-size: $px14-mob;
        top: -7.1vw;
        left: -4.8vw;
        width: 6.1vw;
        height: 6.1vw;
        padding-top: 0.4vw;
      }
    }
  }

  &__cabinet {
    width: 3.5vw;
    font-weight: $font-medium;

    @include mobile {
      width: 19%;
    }

    &-logo {
      width: 65%;
    }

    &-name {
      display: none;
    }
  }

  &__lang {
    margin: 0 0.75vw;

    @include mobile {
      width: 35%;
      margin: 2vw 0.75vw;
    }
  }

  &__lang {
    /* temporarily */
    display: none;
  }

  &__hamburger {
    &-content {
      padding: 12vw 4vw 0 4vw;

      /* temporarily */
      justify-content: center;
    }

    &-holder {
      position: fixed;
      top: 0;
      left: 101vw;
      transition: 0.4s all ease-in-out;
      background-color: $white;
    }

    &-active {
      left: 0;
    }

    &-action {
      position: relative;
      z-index: 1;

      & > div {
        width: 8vw;
        padding: 2vw;
      }
    }

    @include desktop {
      display: none;
    }
  }
}

.header,
.footer {
  &__logo {
    width: 13vw;
    position: relative;

    @include mobile {
      width: 40%;
    }
  }

  &__lang {
    &-current {
      font-weight: $font-medium;
    }
  }

  &__social {
    &-logo {
      width: 2.2vw;
      margin-right: 1.5vw;

      @include mobile {
        width: 11vw;
        margin-right: 7.5vw;
      }

      &:last-child {
        margin-right: 0;
      }
    }
  }
}
