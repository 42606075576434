.calendar {
  &__main {
    h5 {
      font-size: $px16;

      @include mobile {
        font-size: $px16-mob;
      }
    }
  }

  &__year {
    margin-top: 40px;
    display: flex;
    column-gap: 20px;
  }

  &__items {
    font-size: 2rem;
    cursor: pointer;
  }

  &__items_active {
    opacity: 0.5;
  }

  &__events {
    flex-wrap: wrap;
    column-gap: 1.3vw;

    h2 {
      font-size: $px22;

      @include mobile {
        font-size: $px22-mob;
      }
    }
  }

  &__event {
    width: 23.5%;
    align-self: stretch;
    padding: 1vw 0;
    min-height: 16vw;
    margin-bottom: 1.4vw;

    @include mobile {
      width: 49%;
      padding: 2vw 0;
      min-height: 50vw;
      margin-bottom: 5vw;
    }

    &-header,
    &-footer {
      width: 80%;

      @include mobile {
        width: 90%;
      }
    }

    &-header {
      h2 {
        margin: 0;
      }

      h5 {
        font-weight: $font-medium;
      }

      &--logo {
        width: 4vw;
        height: 4vw;
        flex-shrink: 0;

        img {
          object-fit: contain;
          height: 100%;
        }

        @include mobile {
          width: 10vw;
          height: 10vw;
        }
      }
    }

    &-footer {
      h2 {
        font-weight: $font-black;
        text-transform: none;
        margin-bottom: 0.5vw;
      }

      &-action {
        margin-top: 2vw;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        gap: 1vw;
      }
    }

    &-protocol {
      margin: 0;
      width: fit-content;
    }
  }

  &__heading {
    font-size: $px32;
    font-weight: $font-medium;
    margin-top: 3vw;

    @include mobile {
      font-size: $px32-mob;
    }
  }
}
