.contribution {
  &__heading {
    margin: 5vw 0 3vw;
  }

  .advert,
  .privilege {
    margin-top: 3vw;
  }

  .advert {
    h2 {
      font-weight: $font-black;
    }

    h4 {
      white-space: pre-line;
    }

    &__photo {
      width: 45%;
    }

    &__text {
      width: 59%;
      padding: 1vw 2vw 2vw;
      align-self: stretch;
    }

    button {
      width: 9vw;

      @include desktop {
        font-size: $px16;
      }

      @include mobile {
        width: 20vw;
      }
    }
  }

  .cards {
    @include mobile {
      flex-wrap: wrap;
    }

    &__card {
      margin: 0;
      padding: 1.15vw;
      align-self: stretch;
      height: 100%;
      display: flex !important;

      @include mobile {
        padding: 2vw;
      }

      &-logo {
        margin-bottom: 1vw;
        width: 8vw;
        height: 5vw;
        min-height: unset;
        background-color: transparent;

        @include mobile {
          width: 20vw;
          height: 15vw;
        }

        img {
          width: 100%;
          height: 100%;
          object-fit: contain;
        }
      }
    }

    h5 {
      line-height: 1.5;
      margin-bottom: 1vw;
    }
  }

  &__card {
    &-footer {
      min-height: 6vw;
      width: 100%;
    }

    &-title {
      margin-bottom: 1vw;
      font-weight: $font-medium;
    }

    &-sale {
      color: #3262aa;
      font-weight: $font-bold;
    }
  }

  &__price {
    h2 {
      margin: 0 0.5vw;
    }
  }

  &__popup {
    &-upper {
      h4 {
        width: 100%;
      }

      h6 {
        margin-left: 0.5vw;
      }
    }

    .form &-lower {
      h4 {
        margin-right: 0.5vw;
        margin-bottom: 0;
      }

      button {
        margin: 1vw 0 0 0;
      }
    }
  }

  &__slider {
    &-slide {
      outline: none;
      padding: 0 0.5vw;
    }

    .slick {
      &-track {
        display: flex !important;
      }

      &-slide {
        display: flex;
        justify-content: center;
        height: inherit;

        & > * {
          border-radius: 24px;
          object-fit: cover;
          width: 15.55vw;
          box-sizing: content-box;

          @include mobile {
            $slide-size-mobile: 46.6vw;

            width: 46.6vw;
          }
        }
      }

      &-prev,
      &-next {
        z-index: 10;
        width: 3vw;
        height: auto;

        @include mobile {
          width: 8vw;
        }

        &::before {
          display: none;
        }
      }

      &-prev {
        top: calc(50% - 1.5vw);
        left: -1vw;
        transform: scale(-1, 1);

        @include mobile {
          top: calc(50% - 4vw);
          left: 0;
        }
      }

      &-next {
        right: -1vw;

        @include mobile {
          right: 0;
        }
      }
    }
  }
}
