@mixin desktop {
  @media screen and (min-width: 1100px) {
    @content;
  }
}

@mixin mobile {
  @media screen and (max-width: 1099.99px) {
    @content;
  }
}
