button {
  background: $deepblue;
  border-radius: 1vw;
  border: none;
  padding: 0.6vw 1vw;
  color: $white;
  font-family: 'Gotham Pro';
  font-size: $px16;
  font-weight: $font-medium;
  cursor: pointer;

  @include mobile {
    font-size: $px16-mob;
    padding: 2.6vw 3vw;
    border-radius: 3vw;
  }

  form:invalid &.inactive,
  &:disabled {
    background-color: rgba(49, 95, 182, 0.5);
    cursor: default;
  }
}
