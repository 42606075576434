.team {
  &__main {
    padding-top: 4vw;

    .popup {
      .form {
        padding: 0;

        @include mobile {
          height: 100%;
        }
      }

      &__holder {
        width: 80vw;
        max-height: 90vh;
        display: flex;
        align-items: center;

        @include mobile {
          width: 95vw;
          height: 95vh;
          overflow-y: auto;
          border-radius: 3vw;
        }
      }

      &__close {
        z-index: 3;
      }

      .advert {
        margin-top: 0;

        @include mobile {
          flex-direction: column;
        }

        &__photo {
          width: 50%;

          @include mobile {
            width: 100%;
            max-height: 45vh;
            overflow: hidden;
          }

          img {
            border-radius: 1.2vw 0 0 1.2vw;
            height: 100%;
            object-fit: cover;

            @include mobile {
              border-radius: 3vw 3vw 0 0;
            }
          }
        }

        &__text {
          justify-content: flex-start;
          padding-top: 6vw;
          width: 50%;
          height: 100%;
          max-height: 52vw;
          overflow-y: auto;

          @include mobile {
            width: 100%;
            max-height: none;
          }
        }

        &__data {
          margin-bottom: auto;

          p,
          h1,
          h2,
          h3,
          h4,
          h5,
          h6 {
            font-size: $px24;
            line-height: $px24;
            margin: 2vw 0;

            @include mobile {
              font-size: $px24-mob;
              line-height: $px24-mob;
              margin: 6vw 0;
            }
          }
        }
      }

      .cards__card {
        &-title {
          font-size: $px32;
          line-height: $px32;

          @include mobile {
            font-size: $px32-mob;
            line-height: $px32-mob;
            margin: 6vw 0;
          }
        }

        &-subtitle {
          font-size: $px38;
          line-height: $px38;

          @include mobile {
            font-size: $px38-mob;
            line-height: $px38-mob;
            margin: 6vw 0;
          }
        }
      }

      button {
        width: 100%;
      }
    }
  }

  .cards {
    @include mobile {
      gap: 3vw;
    }

    &__card {
      padding: 0;

      &-logo img {
        width: 100%;
        height: 16vw;
        object-fit: cover;
        border-radius: 1.5vw 1.5vw 0 0;

        @include mobile {
          height: 100%;
        }
      }

      &-data {
        padding: 1.5vw;
        display: flex;
        flex-direction: column;

        @include mobile {
          padding: 3vw;
        }
      }

      &-title,
      &-subtitle {
        text-transform: uppercase;
      }

      &-title {
        font-size: $px18;
        font-weight: $font-regular;
        margin-bottom: 2vw;

        @include mobile {
          font-size: $px18-mob;
        }
      }

      &-subtitle {
        font-size: $px24;
        font-weight: $font-bold;
        margin-bottom: 2vw;

        @include mobile {
          font-size: $px24-mob;
        }
      }

      &-button {
        align-self: stretch;
      }
    }
  }
}
