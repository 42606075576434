.product {
  .advert {
    &__store {
      h1 {
        font-size: $px32;

        @include mobile {
          font-size: $px32-mob;
        }
      }

      .advert__text {
        width: 100%;

        @include mobile {
          padding: 4vw 0 4vw 4vw;
        }
      }
    }
  }

  h2 {
    font-size: $px32;
    text-transform: none;
    color: $black;

    @include mobile {
      font-size: $px32-mob;
    }
  }

  &__holder {
    margin-top: 1.5vw;

    @include mobile {
      flex-wrap: wrap;
    }
  }

  &__photo {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    $size: 7vw;
    $size-mob: 20vw;

    &-images {
      overflow-x: auto;
      width: 100%;
      box-sizing: content-box;
      min-height: $size;
      max-height: $size;
      padding: 1.5vw 0;
      display: flex;
      align-items: center;
      justify-content: flex-start;

      @include mobile {
        padding: 6vw 0;
        min-height: $size-mob;
        max-height: $size-mob;
      }
    }

    &-image {
      width: auto;
      max-width: 9vw;
      height: $size;
      object-fit: contain;
      margin-right: 2vw;
      cursor: pointer;

      @include mobile {
        max-width: 25vw;
        margin-right: 8vw;
        height: $size-mob;
      }

      &:last-child {
        margin-right: 0;
      }

      &--current {
        transform: translate3d(0, 0, 0);
        filter: drop-shadow(0 0 0.5vw #000);

        @include mobile {
          filter: drop-shadow(0 0 1vw #000);
        }
      }
    }

    & > img {
      object-fit: contain;
      height: 31.8vw;

      @include mobile {
        width: 70%;
        height: auto;
      }
    }
  }

  &__photo,
  &__data {
    width: 49%;

    @include mobile {
      width: 100%;
    }
  }

  &__char {
    &-title {
      color: $gr2;
      font-weight: $font-bold;
      margin: 1.5vw 0 1vw 0;

      @include mobile {
        margin: 5vw 0 3vw 0;
      }
    }

    &-text {
      white-space: pre-wrap;
    }
  }

  &__price {
    &-title {
      margin: 1.5vw 0 1vw 0;

      @include mobile {
        margin-top: 6vw;
      }
    }

    &--old {
      color: rgba(68, 68, 68, 0.6) !important;
      font-size: $px24 !important;
      font-weight: 400;
      text-decoration-line: line-through;

      @include mobile {
        font-size: $px24-mob !important;
      }
    }

    h2 {
      margin-bottom: 2vw;
    }
  }

  &__size {
    .store__options-filters {
      &--filters {
        margin: 1vw 0;
        gap: 1vw;
        max-width: none;

        @include mobile {
          margin: 3vw 0;
          gap: 2vw;
        }
      }

      &--filter {
        font-size: $px22;
        width: 3.2vw;
        height: 3.2vw;
        background-color: $white;
        margin: 0;

        @include mobile {
          width: 10vw;
          height: 10vw;
          font-size: $px22-mob;
        }

        &:hover {
          background-color: $deepblue;
        }

        &:last-child {
          margin-right: 0;
        }
      }
    }

    &-table {
      margin-bottom: 3vw;
      text-decoration: underline;
      cursor: pointer;
    }
  }

  &__action {
    button {
      padding: 0.5vw 1.5vw;
      margin-right: 1vw;
    }
  }

  &__available {
    line-height: 2.3;
    color: $gr2;
  }

  &__delivery {
    margin-top: 1vw;
    color: $gr3;
  }

  &__recommend {
    .store {
      &__product {
        width: 23.1%;
        margin-bottom: 1.25vw;
        margin: 0.6vw;

        @include mobile {
          width: 45.7%;
          margin: 2vw;
        }
      }
    }
  }

  &__sizetable {
    .table {
      margin-bottom: 2vw;

      tbody {
        background-color: $white;
      }

      td {
        &:first-child {
          padding: 0 0 0 1.5vw;
          text-align: left;
        }
      }
    }
  }
}
