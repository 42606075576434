.cabinet {
  &__main {
    margin-top: 4vw;

    @include mobile {
      flex-wrap: wrap;
    }
  }

  h2 {
    text-transform: none;
  }

  &__heading {
    margin: 0 0 3vw 0;

    &-wrapper {
      margin: 0 0 3vw 0;
      display: flex;
      align-items: center;
      justify-content: flex-start;

      .cabinet__heading {
        margin: 0;
        display: inline;
      }
    }

    &-logo {
      width: 5vw;
      height: 5vw;
      margin-right: 1.5vw;
      object-fit: contain;

      @include mobile {
        width: 10vw;
        height: 10vw;
        border-radius: 5vw;
        margin-right: 2.5vw;
      }
    }
  }

  &__left {
    width: 25%;

    @include mobile {
      width: 100%;
      margin-bottom: 4vw;
    }

    button {
      margin-top: 1vw;

      @include mobile {
        margin-top: 4vw;
      }
    }
  }

  &__right {
    width: 73%;

    @include mobile {
      width: 100%;
    }

    input {
      @include mobile {
        margin-bottom: 4vw;
      }
    }

    .login__form {
      &-left,
      &-right {
        width: 47%;

        @include mobile {
          width: 100%;
        }
      }

      .select::after {
        margin-top: -0.6vw;

        @include mobile {
          margin-top: -1.5vw;
        }
      }

      @include mobile {
        flex-direction: column;
      }
    }
  }

  &__pages {
    padding: 0.5vw 1vw;

    a:last-child {
      .cabinet__page {
        border-bottom: none;
      }
    }
  }

  &__page {
    padding: 1vw 0;
    border-bottom: 0.05vw solid #323232;
    cursor: pointer;
    color: $black;

    @include mobile {
      padding: 3vw 0;
    }

    &:hover,
    &--current {
      color: #3262aa;
      font-weight: $font-bold;
    }

    svg {
      display: inline;
      width: 1vw;
      margin-right: 1vw;

      @include mobile {
        width: 5vw;
        margin-right: 3vw;
      }
    }
  }

  &__settings,
  &__password,
  &__event {
    padding: 2vw 2vw;

    .login__form {
      width: 87%;

      @include mobile {
        width: 100%;
      }
    }

    label {
      font-weight: $font-bold;
    }

    input {
      background-color: transparent;
      border-radius: 0.25vw;

      @include mobile {
        margin-top: 4vw;
      }
    }
  }

  &__licenses {
    &-license {
      width: 16vw;
      background-color: $deepblue;
      border-radius: 1vw;
      padding: 1.5vw 2vw;
      color: $white;

      @include mobile {
        width: 100%;
        border-radius: 3vw;
        padding: 6vw;
      }

      &-image {
        padding: 0 1vw;

        @include mobile {
          padding: 0 10vw;
        }
      }

      &-data {
        margin-top: 1.5vw;

        @include mobile {
          margin-top: 6vw;
        }
      }

      &-index {
        font-size: $px18;
        font-weight: $font-bold;

        @include mobile {
          font-size: $px18-mob;
        }
      }

      &-expiration {
        font-size: $px12;

        @include mobile {
          font-size: $px12-mob;
        }
      }

      &--expired {
        color: $error;
      }
    }

    &-button {
      margin-top: 1.5vw;
    }
  }

  &__settings,
  &__event-create {
    margin-bottom: 2vw;
  }

  &__password {
    .login__form-left {
      button {
        width: 55%;
      }

      h5 {
        margin: 0 0 0 1vw;
      }
    }
  }

  &__qual {
    &.form {
      background-color: $gr1;
    }

    .login__form {
      width: 100%;

      input {
        background-color: $white;
      }
    }

    &-protocols {
      margin-top: 2vw;

      .cards {
        grid-template-columns: repeat(3, 1fr);

        @include mobile {
          grid-template-columns: repeat(2, 1fr);
        }

        &__card {
          min-height: 22vw;

          @include mobile {
            min-height: 65vw;
          }
        }
      }
    }
  }

  &__event {
    .login__form {
      textarea {
        background-color: transparent;
        border: 0.05vw solid #323232;
        margin: 1vw 0 1vw;
        border-radius: 0.25vw;
        min-height: 6vw;
      }

      h4 {
        font-size: $px18;
        font-weight: $font-medium;
        color: rgba(51, 51, 51, 1);
        margin: 0.5vw 0;

        @include mobile {
          font-size: $px18-mob;
          margin: 2vw 0;
        }
      }

      &-left {
        margin-bottom: 1vw;
      }

      &-action {
        p {
          font-size: $px12;
          font-weight: $font-bold;
          margin: 0.5vw 0 1.25vw;

          @include mobile {
            font-size: $px12-mob;
          }
        }

        h5 {
          margin: 0;
        }

        button {
          margin: 0 1vw 0 0;
          width: 40%;

          @include mobile {
            width: 100%;
            margin: 4vw 4vw 0 0;
          }
        }

        &--bottom {
          margin: 1vw 0;

          button {
            width: auto;
            padding: 0.5vw 2vw;

            @include mobile {
              padding: 2vw 4vw;
            }
          }
        }
      }

      &-checkbox {
        margin-bottom: 1vw;
      }

      .checkbox {
        width: 30%;

        @include mobile {
          width: 50%;
        }
      }
    }
  }

  &__history {
    &-buttons {
      @include mobile {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: center;
      }

      button {
        margin-right: 1vw;
        border-radius: 0.25vw;
        font-size: $px16;
        padding: 0.75vw 2vw;

        @include mobile {
          font-size: $px16-mob;
          margin-right: 0;
          margin-bottom: 2vw;
          border-radius: 2vw;
          padding: 2vw 4vw;
        }
      }
    }

    &-action {
      button {
        margin-left: 1vw;
      }
    }

    &-item {
      margin-bottom: 1vw;
    }

    &-data {
      padding: 1.5vw 0;
      margin: 1.5vw 0;
      border-top: 0.05vw solid #e4e4e8;
      border-bottom: 0.05vw solid #e4e4e8;

      @include mobile {
        flex-wrap: wrap;
      }

      h5 {
        font-size: $px12;
        font-weight: $font-bold;

        @include mobile {
          font-size: $px18-mob;
        }
      }

      p {
        font-size: $px16;

        @include mobile {
          font-size: $px24-mob;
        }
      }

      h5,
      p {
        @include mobile {
          width: 100%;
        }
      }

      &--block {
        @include mobile {
          width: 100%;

          div {
            width: 100%;
            flex-wrap: wrap;

            & > div {
              width: 100%;
            }
          }

          h3 {
            font-size: $px86-mob;
          }
        }
      }

      &--logo {
        width: 25%;

        @include mobile {
          display: flex;
          justify-content: center;
          align-items: center;

          img {
            width: 50%;
            margin: 4vw 0;
          }
        }
      }

      &--first {
        width: 21%;
      }

      &--second {
        width: 27.5%;
        border-left: 0.05vw solid #e4e4e8;
        border-right: 0.05vw solid #e4e4e8;
        padding: 0 1vw;

        @include mobile {
          border: none;
          padding: 0;
        }
      }

      &--third {
        p,
        h5 {
          text-align: right;

          @include mobile {
            text-align: left;
          }
        }

        @include mobile {
          align-items: flex-start;
        }
      }

      &--first,
      &--second,
      &--third {
        @include mobile {
          flex-flow: row;
          flex-wrap: wrap;
        }
      }

      &--logo,
      &--first,
      &--second,
      &--third {
        @include mobile {
          width: 100%;
        }
      }
    }
  }

  &__events {
    .calendar__event {
      width: 31.2%;
      margin: 0.5vw;
      padding: 1vw 0 0;

      @include mobile {
        width: 46.8%;
        margin: 1.5vw;
      }

      h2 {
        font-size: $px22;

        @include mobile {
          font-size: $px22-mob;
        }
      }

      h5 {
        font-size: $px16;

        @include mobile {
          font-size: $px16-mob;
        }
      }

      &-link {
        margin: 2vw 0 1vw;
        text-decoration: underline;
      }

      &-footer {
        margin-top: 4vw;
      }

      &-status {
        width: 100%;
        background-color: #c5ccd7;
        padding: 1.5vw;
        border-bottom-left-radius: 1vw;
        border-bottom-right-radius: 1vw;

        @include mobile {
          padding: 2.5vw;
        }

        &--status {
          font-weight: $font-medium;
          margin-bottom: 0.25vw;
        }

        .calendar__event-link {
          margin: 1vw 0 0;
          font-weight: $font-bold;
          cursor: pointer;
        }
      }
    }

    .cabinet__qual.form {
      width: 100%;
      margin: 0.5vw 0.5vw 1vw;
    }
  }

  &__activity {
    &-location {
      width: 100%;
      padding: 2vw 0;
      border-bottom: 0.05vw solid $black;

      @include mobile {
        padding: 5vw 0;
      }
    }

    &-map {
      width: 3%;
      margin-right: 1vw;

      @include mobile {
        width: 5%;
        margin-right: 3vw;
      }
    }

    &-data {
      h3 {
        font-size: $px22;
        margin: 0 0 0.5vw;
        text-transform: none;

        @include mobile {
          font-size: $px22-mob;
          margin: 0 0 2.5vw;
        }
      }

      h5 {
        span {
          border-right: 0.05vw solid $black;
          padding-right: 0.5vw;
          margin-right: 0.5vw;

          @include mobile {
            padding-right: 1.5vw;
            margin-right: 1.5vw;
          }
        }
      }

      &--active {
        color: rgba(94, 233, 45, 1);
        font-weight: $font-bold;
      }
    }
  }
}
