.popup {
  position: fixed;
  left: 0;
  top: 0;
  z-index: 100;
  background-color: rgba(0, 0, 0, 0.5);

  &__holder {
    width: 60vw;

    @include mobile {
      width: 95%;
    }
  }

  &__close {
    position: absolute;
    top: 0;
    right: 0;
    width: 3vw;
    cursor: pointer;

    @include mobile {
      width: 8vw;
    }
  }
}
