.accreditedRaces {
  &__text {
    padding: 2.5vw 0;
    font-size: $px18;
    letter-spacing: $spacing;

    @include mobile {
      font-size: $px18-mob;
    }
  }
}
