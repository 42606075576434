.news {
  &__main {
    padding-top: 4vw;
  }

  &__wrapper {
    margin-bottom: 4vw;
  }

  .cards {
    grid-template-columns: repeat(3, 1fr);
    gap: 2vw;

    @include mobile {
      grid-template-columns: repeat(1, 1fr);
    }

    &__card {
      position: relative;
      padding: 2.5vw;
      min-height: 25vw;
      height: 100%;
      background-repeat: no-repeat;
      background-position: center center;
      background-size: cover;

      @include mobile {
        padding: 6vw;
        height: 100vw;
        border-radius: 4vw;
      }

      &-title,
      &-button {
        color: $white;
      }

      &-title {
        font-size: $px32;
        line-height: $px32;
        font-weight: $font-bold;
        margin-bottom: 2.5vw;

        @include mobile {
          font-size: $px32-mob;
          line-height: $px32-mob;
          margin-bottom: 6vw;
        }
      }

      &-button {
        font-size: $px24;
        font-weight: $font-regular;
        text-decoration: none;

        @include mobile {
          font-size: $px24-mob;
        }

        &:hover {
          text-decoration: underline;
        }
      }
    }
  }

  &__subscription .advert {
    padding: 2vw;

    @include mobile {
      flex-direction: column;
      padding: 6vw;
      gap: 3vw;
    }

    &__text {
      display: flex;
      flex-direction: column;
      gap: 1vw;
      padding: 0;

      @include mobile {
        gap: 3vw;
      }
    }

    h3 {
      margin-bottom: 0;
    }

    p {
      margin: 0;
      font-size: $px18;

      @include mobile {
        font-size: $px18-mob;
      }
    }

    label {
      font-size: $px20;

      @include mobile {
        font-size: $px20-mob;
        line-height: $px24-mob;
      }
    }

    input[type='checkbox'] {
      width: 1vw;
      height: 1vw;
      margin: 0.2vw;
      padding-top: 0.2vw;

      @include mobile {
        width: 4vw;
        height: 4vw;
        margin: 0.4vw 1vw 0.4vw 0;
      }
    }
  }

  &__article {
    .news__main {
      padding-top: 1vw;
    }

    .banner {
      width: 100%;
      height: 55vh;
      background-repeat: no-repeat;
      background-position: center center;
      background-size: cover;

      &__container {
        padding-top: 0;

        @include mobile {
          margin-bottom: 4vw;
        }

        h3,
        h5,
        h6 {
          color: $white;
        }

        h3 {
          font-size: $px32;
          font-weight: $font-bold;

          @include mobile {
            font-size: $px56-mob;
          }
        }

        h5 {
          font-size: $px24;

          @include mobile {
            font-size: $px38-mob;
          }
        }

        h6 {
          font-size: $px18;

          @include mobile {
            font-size: $px32-mob;
          }
        }
      }
    }

    &-text {
      margin-bottom: 3vw;

      p,
      h1,
      h2,
      h3,
      h4,
      h5,
      h6 {
        font-size: $px24;
        margin: 2vw 0;

        @include mobile {
          font-size: $px24-mob;
          margin: 6vw 0;
        }
      }
    }
  }
}
