.table {
  font-size: $px16;
  font-weight: $font-bold;
  width: 100%;
  text-align: left;

  @include mobile {
    display: block;
    overflow-x: auto;
    white-space: nowrap;
    font-size: $px14-mob;
  }

  thead {
    background-color: #333f51;
    color: $white;
  }

  tbody {
    background-color: #edeff2;
  }

  td,
  th {
    padding-left: 0.75vw;

    &:first-child {
      padding: 0 0.25vw;
      text-align: center;

      @include mobile {
        padding: 0 2vw;
      }
    }

    @include mobile {
      padding-left: 2vw;
    }
  }

  th {
    padding-top: 1.5vw;
    padding-bottom: 1.5vw;

    @include mobile {
      padding-top: 3.5vw;
      padding-bottom: 3.5vw;
    }

    &:first-child {
      border-top-left-radius: 1vw;

      @include mobile {
        border-top-left-radius: 2vw;
      }
    }

    &:last-child {
      border-top-right-radius: 1vw;

      @include mobile {
        border-top-right-radius: 2vw;
      }
    }
  }

  td {
    padding-top: 0.75vw;
    padding-bottom: 0.75vw;

    @include mobile {
      padding-top: 3vw;
      padding-bottom: 3vw;
      padding-right: 2vw;
    }
  }

  tbody tr:last-child {
    td:first-child {
      border-bottom-left-radius: 1vw;
    }

    td:last-child {
      border-bottom-right-radius: 1vw;
    }
  }

  &__site {
    &:hover {
      text-decoration: underline;
    }
  }

  &__race {
    padding: 0 !important;

    img {
      width: 40%;
    }
  }

  &__category {
    span {
      padding: 0.25vw 1vw;
      background-color: #db2525;
      border-radius: 1vw;
      color: $white;
    }
  }
}
