.cards {
  display: grid;
  gap: 1vw;
  grid-template-columns: repeat(4, 1fr);

  @include mobile {
    gap: 2vw;
    grid-template-columns: repeat(2, 1fr);
  }

  &__card {
    background-color: $gr1;
    border-radius: 1vw;
    padding: 1.25vw;
    min-height: 20vw;
    min-width: -webkit-fill-available;

    @include mobile {
      padding: 2vw 2vw 4vw;
      min-height: 60vw;
    }

    &-logo {
      border-radius: 1vw 1vw 0 0;
      background-color: $white;
      min-height: 12vw;

      @include mobile {
        min-height: 30vw;
      }

      img {
        width: 50%;

        &.cards__card-logo--document {
          width: 35%;
        }
      }
    }

    &-title {
      font-size: $px16;
      font-weight: $font-black;
      color: $gr2;

      @include mobile {
        font-size: $px16-mob;
      }
    }

    &-subtitle {
      font-size: $px16;
      color: $gr2;
      margin: 0;

      @include mobile {
        font-size: $px16-mob;
      }
    }

    &-button {
      font-size: $px16;
      font-weight: $font-medium;
      text-decoration: underline;
      cursor: pointer;

      @include mobile {
        font-size: $px12-mob;
      }

      p {
        margin: 0;
      }
    }
  }
}
